import { rules } from '@/plugins/rules'
import { walletStore } from '@/stores/wallet-store'
import { observable, action, computed } from 'mobx'

export class RegisterViewModel {
  @observable emailAddress = ''
  @observable emailAddressError = false

  @observable username = ''
  @observable usernameError = false

  @observable agreePolicy = false
  @observable agreePolicyError = false

  constructor() {
    //
  }

  @action.bound onEmailAddressChange(val: string) {
    if (this.emailAddressError) this.emailAddressError = false
    this.emailAddress = val
  }

  @action.bound onUsernameChange(val: string) {
    if (this.usernameError) this.usernameError = false
    this.username = val
  }

  @action.bound onAcceptTermChange(val: boolean) {
    if (this.agreePolicyError) this.agreePolicyError = false
    this.agreePolicy = !this.agreePolicy
  }

  @action.bound register() {
    if (!this.validation()) {
      return
    }
    walletStore.updateUserInfo(this.emailAddress, this.username, 'register')
  }

  @action validation(): boolean {
    if (
      rules.required(this.emailAddress) === 'This is required field' ||
      rules.email(this.emailAddress) === 'Wrong format'
    ) {
      this.emailAddressError = true
    }

    if (rules.required(this.username) === 'This is required field') this.usernameError = true

    if (!this.agreePolicy) this.agreePolicyError = true

    return !(this.emailAddressError || this.usernameError || this.agreePolicyError)
  }
}
