















































import { Component, Vue, Provide } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { walletStore } from '@/stores/wallet-store'
import { RegisterViewModel } from '../viewmodels/register-viewmodel'
@Observer
@Component({
  components: {
    RegisterForm: () => import('../components/register-form.vue'),
  },
})
export default class Register extends Vue {
  @Provide() vm = new RegisterViewModel()
  wallet = walletStore
}
